<template>
  <v-container class="contact-section" fluid>
    <!-- Fondo gris claro -->
    <v-row class="justify-center" style="background-color: #f5f5f5; padding: 40px;">
      <v-col cols="12" sm="8" md="6" class="text-center">
        <!-- Título y subtítulo -->
        <h1 class="contact-title">Contactanos</h1>
        <p class="contact-subtitle">
          Si tienes alguna duda, comentario o deseas más información, completa el siguiente formulario para ponernos en contacto contigo
        </p>
      </v-col>
    </v-row>

    <!-- Formulario de contacto -->
    <v-row class="justify-center" style="background-color: #f5f5f5; padding-bottom: 40px;">
      <v-col cols="12" sm="8" md="6">
        <v-form>
          <v-row>
            <!-- Nombre -->
            <v-col cols="12" sm="6">
              <v-text-field
                label="Tu nombre..."
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Apellidos -->
            <v-col cols="12" sm="6">
              <v-text-field
                label="Tus apellidos..."
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Email -->
            <v-col cols="12" sm="6">
              <v-text-field
                label="Tu email..."
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Teléfono celular -->
            <v-col cols="12" sm="6">
              <v-text-field
                label="Tu número celular..."
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Asunto del mensaje -->
            <v-col cols="12">
              <v-text-field
                label="Asunto del mensaje..."
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Mensaje -->
            <v-col cols="12">
              <v-textarea
                label="Dinos tu mensaje"
                outlined
                dense
                rows="4"
              ></v-textarea>
            </v-col>

            <!-- Botón de enviar -->
            <v-col cols="12" class="text-center">
              <v-btn
                color="primary"
                class="white--text"
                large
              >
                ENVIAR
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ContactForm',
};
</script>

<style scoped>
.contact-section {
  background-color: #f5f5f5;
}

.contact-title {
  color: #00bfff;
  font-weight: bold;
  margin-bottom: 16px;
}

.contact-subtitle {
  color: #666666;
  font-size: 16px;
  margin-bottom: 24px;
}

v-text-field, v-textarea {
  margin-bottom: 16px;
}

v-btn {
  background-color: #6c63ff;
}
</style>