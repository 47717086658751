<template>
    <section class="ideas">
        <div class="ideas-libres">
            <v-container fluid class="px-0">
                <!-- Título principal -->
                <v-row justify="center" class="text-center mb-6" style="padding: 20px;">
                  <v-col cols="12" class="d-flex align-center justify-center">
                    <!-- Contenedor de flex para alinear elementos -->
                    <div class="d-flex align-items-center justify-content-center">
                      
                      <!-- Texto "Ideas Libres" -->
                      <h1 class="display-2 font-weight-bold ideas-text">
                        Ideas<br>Libres
                      </h1>

                      <!-- Imagen del bombillo en el medio -->
                      <img 
                        src="../assets/icono_bombillo.png" 
                        alt="Bombillo" 
                        class="bombillo-image mx-4"
                      />

                      <!-- Texto a la derecha del bombillo -->
                      <div class="text-container ml-4">
                        <h2 class="subtitle-1 bold-text three-line-title">
                          CREAMOS <br> CONTENIDO PARA <br> INFLUENCERS
                        </h2>
                        <p class="caption small-text">
                          y potenciamos el mercado de <br> distintas marcas a nivel mundial
                        </p>
                      </div>

                    </div>
                  </v-col>
                </v-row>
            
                <!-- Tarjetas de contenido -->
                <v-row justify="center" class="mb-6">
                    <v-col cols="12" md="4">
                    <v-card outlined class="pa-3 text-center">
                        <v-img src="../assets/contenido_redes.png" height="200px"></v-img>
                        <v-card-title>Contenido en Redes</v-card-title>
                        <v-card-subtitle>Te ayudamos a crear tu propio contenido</v-card-subtitle>
                    </v-card>
                    </v-col>
            
                    <v-col cols="12" md="4">
                    <v-card outlined class="pa-3 text-center">
                        <v-img src="../assets/conecta_con_marcas.png" height="200px"></v-img>
                        <v-card-title>Conecta con Marcas</v-card-title>
                        <v-card-subtitle>Asociamos marcas con tu imagen para ayudarte a llegar a públicos objetivos</v-card-subtitle>
                    </v-card>
                    </v-col>
            
                    <v-col cols="12" md="4">
                    <v-card outlined class="pa-3 text-center">
                        <v-img src="../assets/crecimiento_redes.png" height="200px"></v-img>
                        <v-card-title>Crecimiento en Redes</v-card-title>
                        <v-card-subtitle>Desarrollamos estrategias de marketing para aumentar tu cantidad de seguidores</v-card-subtitle>
                    </v-card>
                    </v-col>
                </v-row>
            
                <!-- Texto final -->
                <v-row justify="center">
                    <v-col cols="12" class="text-center">
                    <p class="font-weight-bold" style="color:#5b59eb;">ESPECIALISTAS EN CREACIÓN DE VÍNCULOS</p>
                    </v-col>
                </v-row>

                <v-col cols="12" class="text-center mt-2">
                  <v-icon color="#5b59eb" size="36">mdi-chevron-double-down</v-icon>
                </v-col>
            </v-container>
        </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'IdeasLibres',
  };
  </script>
  
  <style scoped>
  .three-line-title {
    line-height: 1.2; /* Controla la separación entre líneas */
  }

  .d-flex {
    display: flex;
  }

  .ideas-libres {
    background-image: url('../assets/fondoideas.png');
    background-position: right;
    background-repeat: no-repeat;
    padding-left: 20%;
    padding-right: 20%;
  }
  h1 {
    color: #5b59eb;
  }
  .v-card {
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  .v-card:hover {
    transform: scale(1.05);
  }
  .v-card-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .v-card-subtitle {
    font-size: 1rem;
    color: #666;
  }
  .ideas-text {
    color: #6c63ff;
    margin-right: 10px;
  }

  .bombillo-image {
    width: 75px; /* Ajusta el tamaño según sea necesario */
    height: 100px;
  }

  .text-container {
    display: inline-block;
    text-align: left;
    margin-left: 10px;
  }

  .bold-text {
    color: #11045c;
    font-weight: bold;
  }

  .small-text {
    color: #11045c;
    font-size: 12px;
  }
  </style>
  