<template>
  <v-app-bar app color="white" elevation="0" class="header-bar">
    <v-container fluid>
            <v-row align="center" no-gutters>
                <v-col cols="auto" class="d-flex align-center">
                    <img :src="require('../assets/logo.png')" alt="Cloud Host" class="img-fluid">
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex align-center">
                  <v-btn text class="nav-btn">CONTRÁTANOS</v-btn>
                  <v-btn text class="nav-btn">BLOG</v-btn>
                  <v-btn text class="nav-btn">CONTACTO</v-btn>
                  <v-btn text class="nav-btn">PORTAFOLIO</v-btn>
                  <v-btn icon>
                    <v-icon style="color: #5b59eb;">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
            </v-row>
        </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "HeaderPrin",
};
</script>

<style scoped>
.header-bar {
  padding: 0 40px;
  border-bottom: 2px solid #e0e0e0;
  height: 100px;
}

.logo {
  max-height: 50px; 
}

.nav-btn {
  font-family: 'Arial', sans-serif; 
  font-weight: bold;
  color: #5b59eb; 
  font-size: 15px;
  text-transform: none; 
  margin-right: 20px;
}

.nav-btn:hover {
  color: #5b59eb; 
}

.v-app-bar {
  padding: 0 32px; 
  border-bottom: 2px solid #e0e0e0; 
}
</style>

