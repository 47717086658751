<template>
    <v-container class="focus-section" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="8">
          <v-card class="focus-card pa-5" elevation="0">
            <v-row>
              <v-col cols="12" md="12" class="d-flex flex-column align-center text-center">
                <h1 class="focus-title">SÉ PARTE DE NUESTRA FAMILIA</h1>
                <p class="focus-subtitle">Si lo que quieres es hacer crecer tu comunidad, recibir apoyo y vivir de lo que más te gusta no dudes en dejarnos tus datos.</p>
                <v-form>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-facebook"
                        placeholder="Facebook"
                        class="white--text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-instagram"
                        placeholder="Instagram"
                        class="white--text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-youtube"
                        placeholder="YouTube"
                        class="white--text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-tiktok"
                        placeholder="TikTok"
                        class="white--text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-btn color="primary" class="mx-auto white--text">ENVIAR</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "FocusSection",
  };
  </script>
  
  <style scoped>
  .focus-section {
    background-image: url('../assets/banner_postulacion.png');
    padding: 100px 0;
    color: white;
  }
  
  .focus-card {
    background-color: transparent;
    text-align: center;
    color: white;
  }
  
  .focus-title {
    font-size: 32px;
    font-weight: bold;
  }
  
  .focus-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .focus-logo {
    width: 150px;
  }
  
  .white--text {
    color: white !important;
  }
  </style>
  