<template>
  <section class="quienes-somos">
    <div class="quienes-section">
      <v-container class="px-0" fluid>
      <!-- Título principal -->
      <v-row justify="center" class="text-center mb-6">
        <v-col cols="12">
          <v-img src="../assets/icono_pantalla.png" contain max-height="60px" max-width="100%" class="mx-auto"></v-img>
          <h1 class="display-2 font-weight-bold" style="color: #5b59eb;">Quiénes Somos</h1>
          <p class="description">
            Somos la agencia encargada de conectar tu marca y nuestro #TeamFocus de influencers,
            buscando siempre alcanzar tu audiencia ideal y objetivos con la mejor estrategia.
          </p>
          <p class="highlighted-text">
            Contamos con todos los servicios estratégicos para ayudar a marcas e influencers.
          </p>
          <p class="description">
            También nos enfocamos en el crecimiento personal y profesional de influencers,
            brindándole un acompañamiento continuo.
          </p>
        </v-col>
      </v-row>
  
      <!-- Servicios -->
      <v-row justify="center" class="text-center my-6">
        <v-col cols="12">
          <div class="divider-wrapper">
            <v-divider class="divider-line"></v-divider>
            <h2 class="divider-title" style="color: #23ddff;">SERVICIOS</h2>
            <v-divider class="divider-line"></v-divider>
          </div>
        </v-col>
      </v-row>
  
      <v-sheet
      outlined
      class="pa-6"
      style="border: 2px solid #5b59eb; border-radius: 12px;"
    >
      <v-row justify="center" class="mb-6">
        <!-- Tarjeta de Marketing -->
        <v-col cols="12" md="4" lg="2">
          <v-card class="pa-3 text-center" flat>
            <v-icon style="color:#5b59eb;" large>mdi-bullhorn</v-icon>
            <v-card-title>Marketing</v-card-title>
            <v-card-subtitle>
              Posicionamiento de marca, estrategias digitales, social media,
              análisis de datos.
            </v-card-subtitle>
          </v-card>
        </v-col>

        <!-- Tarjeta de Management -->
        <v-col cols="12" md="4" lg="2">
          <v-card class="pa-3 text-center" flat>
            <v-icon style="color:#5b59eb;" large>mdi-account-group</v-icon>
            <v-card-title>Management</v-card-title>
            <v-card-subtitle>
              Asesoramiento para influencers, gestión de colaboraciones y
              contratos.
            </v-card-subtitle>
          </v-card>
        </v-col>

        <!-- Tarjeta de Contenido -->
        <v-col cols="12" md="4" lg="2">
          <v-card class="pa-3 text-center" flat>
            <v-icon style="color:#5b59eb;" large>mdi-content-copy</v-icon>
            <v-card-title>Contenido</v-card-title>
            <v-card-subtitle>
              Creación de contenido digital para redes sociales y campañas
              publicitarias.
            </v-card-subtitle>
          </v-card>
        </v-col>

        <!-- Tarjeta de Creativos -->
        <v-col cols="12" md="4" lg="2">
          <v-card class="pa-3 text-center" flat>
            <v-icon style="color:#5b59eb;" large>mdi-palette</v-icon>
            <v-card-title>Creativos</v-card-title>
            <v-card-subtitle>
              Desarrollo de diseños visuales y propuestas creativas para
              campañas.
            </v-card-subtitle>
          </v-card>
        </v-col>

        <!-- Tarjeta de Relaciones Públicas -->
        <v-col cols="12" md="4" lg="2">
          <v-card class="pa-3 text-center" flat>
            <v-icon style="color:#5b59eb;" large>mdi-handshake</v-icon>
            <v-card-title>Relaciones Públicas</v-card-title>
            <v-card-subtitle>
              Gestión de colaboraciones y alianzas estratégicas con marcas.
            </v-card-subtitle>
          </v-card>
        </v-col>

        <!-- Tarjeta de Eventos -->
        <v-col cols="12" md="4" lg="2">
          <v-card class="pa-3 text-center" flat>
            <v-icon style="color:#5b59eb;"  large>mdi-calendar-star</v-icon>
            <v-card-title>Eventos</v-card-title>
            <v-card-subtitle>
              Organización y gestión de eventos para lanzamientos,
              conferencias, etc.
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  
      <!-- #TeamFocus -->
      <v-row justify="center">
        <v-col cols="12" class="text-right">
          <p class="hashtag">#TeamFocus</p>
        </v-col>
      </v-row>
    </v-container>
    </div>
  </section>
  </template>
  
  <script>
  export default {
    name: 'QuienesSomos',
  };
  </script>
  
  <style scoped>
  .quienes-section {
    background-image: url('../assets/quienes.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-left: 20%;
    padding-right: 20%;
  }

  .divider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .description {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .highlighted-text {
    color: #23ddff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .divider-line {
    flex-grow: 1;
    height: 5px;
    background-color: #23ddff;
    max-width: 100px; 
  }

  .v-card {
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .v-card:hover {
    transform: scale(1.05);
  }
  
  .v-card-title {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .v-card-subtitle {
    font-size: 1rem;
    color: #666;
  }
  
  .hashtag {
    font-size: 2rem;
    color: #5b59eb;
    font-weight: bold;
  }
  </style>
  