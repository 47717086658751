<template>
  <section class="Thematic">
    <div class="tematica">
      <v-container fluid>
      <!-- Título y descripción -->
      <v-row justify="center" class="text-center mb-6">
                <v-col cols="12">
                  <v-img src="../assets/icono_tematica.png" contain max-height="60px" max-width="100%" class="mx-auto"></v-img>
                  <h1 class="display-2 font-weight-bold">Temática</h1>
                  <p class="description">
                    Contamos con una gran red de influencers en Colombia. Cada uno de ellos con audiencias variadas en las principales redes sociales, con enfoques de contenido distintos y temas específicos.
                  </p>
                </v-col>
              </v-row>
          
              <!-- Íconos de categorías -->
              <v-row justify="center" class="text-center mb-6">
                <v-col cols="6" md="3" lg="2">
                  <img src="../assets/icono_gastronomia.png" alt="Gastronomía" class="img-icon" />
                  <p class="icon-label">Gastronomía</p>
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <img src="../assets/icono_cultura.png" alt="Cultura" class="img-icon" />
                  <p class="icon-label">Cultura</p>
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <img src="../assets/icono_viajes.png" alt="Viajes" class="img-icon" />
                  <p class="icon-label">Viajes</p>
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <img src="../assets/icono_tec.png" alt="Tecnología" class="img-icon" />
                  <p class="icon-label">Tecnología</p>
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <img src="../assets/icono_deporte.png" alt="Deportes" class="img-icon" />
                  <p class="icon-label">Deportes</p>
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <img src="../assets/icono_moda.png" alt="Moda" class="img-icon" />
                  <p class="icon-label">Moda</p>
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <img src="../assets/icono_maquillaje.png" alt="Maquillaje" class="img-icon" />
                  <p class="icon-label">Maquillaje</p>
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <img src="../assets/icono_humor.png" alt="Humor" class="img-icon" />
                  <p class="icon-label">Humor</p>
                </v-col>
              </v-row>

    </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ThematicSection',
};
</script>

<style scoped>
/* Onda superior */

/* Sección principal */
.tematica {
  background-color: #5b59eb; /* Fondo azul oscuro */
  color: white;
  position: relative;
  padding-left: 20%;
  padding-right: 20%;
}


.description {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: white;
}

.icon-label {
  font-size: 1.1rem;
  color: cyan;
  margin-top: 10px;
}

.v-icon {
  font-size: 48px;
}

.v-col {
  margin-bottom: 20px;
}
</style>
