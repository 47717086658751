<template>
    <v-footer padless>
      <v-row class="d-flex justify-space-between" no-gutters>
        <!-- Sección Izquierda: Logo e Información de Contacto -->
        <v-col cols="12" md="4" class="text-center text-md-left">
          <div>
            <h3 class="white--text font-weight-bold">FOCUS.<span class="white--text">Global</span></h3>
            <p class="white--text">
              <v-icon color="white" class="mr-2">mdi-map-marker</v-icon> DIRECCION
            </p>
            <p class="white--text">
              <v-icon color="white" class="mr-2">mdi-email</v-icon> EMAIL
            </p>
            <p class="white--text">
              <v-icon color="white" class="mr-2">mdi-whatsapp</v-icon> TELEFONO CELULAR
            </p>
          </div>
          <div class="social-icons mt-3">
            <v-btn icon>
              <v-icon color="white">mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon color="white">mdi-instagram</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon color="white">mdi-youtube</v-icon>
            </v-btn>
          </div>
        </v-col>
  
        <!-- Sección Central: Acerca -->
        <v-col cols="12" md="4" class="text-center">
          <div>
            <h4 class="white--text font-weight-bold">ACERCA</h4>
            <p class="white--text">Historia</p>
            <p class="white--text">Equipo</p>
            <p class="white--text">Términos y Condiciones</p>
            <p class="white--text">Política de Privacidad</p>
          </div>
        </v-col>
  
        <!-- Sección Derecha: Otro -->
        <v-col cols="12" md="4" class="text-center text-md-left">
          <div>
            <h4 class="white--text font-weight-bold">OTRO</h4>
            <p class="white--text">Contacto</p>
            <p class="white--text">Ayuda</p>
            <p class="white--text">Privacidad</p>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </template>
  
  <script>
  export default {
    name: 'CustomFooter'
  }
  </script>
  
  <style scoped>
  .v-footer {
    background-color: #000a12;
    padding: 40px 20px;
  }
  
  h3, h4 {
    margin-bottom: 16px;
  }
  
  p {
    margin-bottom: 8px;
  }
  
  .social-icons v-btn {
    margin: 0 8px;
  }
  
  .v-icon {
    font-size: 24px;
  }
  </style>
  