<template>
  <v-app>
    <v-main>
      <HeaderPrin />
      <HeroSection />
      <IdeasLibres />
      <QuienesSomos />
      <ThematicSection />
      <InfoRedes />
      <FormContact />
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import HeaderPrin from './components/HeaderPrin.vue';
import HeroSection from './components/HeroSection.vue';
import IdeasLibres from './components/IdeasLibres.vue';
import QuienesSomos from './components/QuienesSomos.vue';
import ThematicSection from './components/ThematicSection.vue';
import InfoRedes from './components/InfoRedes.vue';
import FormContact from './components/FormContact.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',

  components: {
    HeaderPrin,
    HeroSection,
    IdeasLibres,
    QuienesSomos,
    ThematicSection,
    InfoRedes,
    FormContact,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>